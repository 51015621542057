<!--
/**
 *@author zhengcc
 *@date 2024-06-11
 财务管理 对账管理 分成信息
*/
-->
<template>
  <custom-list
    table-title="分成信息"
    search-btn-text="查询"
    reset-btn-text="清空"
    :show-header="true"
    :request-call="true"
    :search-data="searchData"
    :search-params="searchParam"
    :table-columns="tableColumns"
    :request-method="dividedIntoInformationsApi.list"
    :table-operate-buttons="tableOperateButtons.filter(item => item.enabled())"
    @query-form="handleSearchConfirm"
    @clear-form="handleSearchClean" />
</template>

<script>
  import CustomList from '@/components/biz/CustomList.vue';
  import { dividedIntoInformationsApi, directPage } from '@/service/apis';
  import { getLastTime, getLastYear } from '@/utils';

  export default {
    name: 'CustomerDividedIntoInformationList',
    components: { CustomList },
    data() {
      return {
        OperatorListData: [], // 归属商家
        polymerizationInfo: {},
        dividedIntoInformationsApi,
        tableData: [],
        tableColumns: [
          { label: '电站编码', prop: 'stationCode', minWidth: '200px' },
          {
            label: '电站名称',
            prop: 'stationName',
            minWidth: '150px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.stationName} placement="top-start">
            //         <span>{row.stationName}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          { label: '归属商家', prop: 'stationOperatorName', minWidth: '100px' },
          {
            label: '分成日期区间',
            prop: 'startTime',
            minWidth: '200px',
            scopedSlots: {
              default: ({ row }) => {
                return `${row.startTime}~${row.endTime}`;
              },
            },
          },
          { label: '按折扣分成金额', prop: 'discountMoney', minWidth: '100px' },
          { label: '服务费分成金额', prop: 'discountServiceMoney', minWidth: '100px' },
          { label: '按次数分成金额', prop: 'discountCountMoney', minWidth: '100px' },
          { label: '按时间分成金额', prop: 'discountTimeMoney', minWidth: '100px' },
          { label: '按电量分成金额', prop: 'discountElectricMoney', minWidth: '100px' },
          { label: '充电次数', prop: 'rechargeCount', minWidth: '100px' },
          {
            label: '充电时长',
            prop: 'rechargeTime',
            minWidth: '100px',

            scopedSlots: {
              default: ({ row }) => {
                return row.rechargeTime ? `${row.rechargeTime}分` : '——';
              },
            },
          },
          { label: '电费', prop: 'electricMoney', minWidth: '100px' },
          { label: '电量', prop: 'electric', minWidth: '100px' },
          {
            label: '是否物业报装电表',
            prop: 'isAloneApply',
            minWidth: '150px',

            scopedSlots: {
              default: ({ row }) => {
                return row.isAloneApply ? '是' : '否';
              },
            },
          },
          { label: '服务费抽成', prop: 'serviceRate', minWidth: '100px' },
          { label: '电费抽成', prop: 'powerRate', minWidth: '100px' },
        ],
        tableOperateButtons: [],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        isAloneApplyData: [
          {
            id: 0,
            name: '否',
          },
          {
            id: 1,
            name: '是',
          },
        ],
        preferentialData: [
          {
            id: 0,
            name: '优惠前',
          },
          {
            id: 1,
            name: '优惠后',
          },
        ],
        searchParam: {
          date: [],
          // billDateType: 1,
          startTime: '',
          endTime: '',
          isDiscount: 1,
        },
      };
    },
    computed: {
      // dividedIntoInformationsApi() {
      //   return dividedIntoInformationsApi;
      // },
      searchData() {
        return [
          {
            key: 'isAloneApply',
            label: '是否物业报装',
            placeholder: '请选择',
            comName: 'YkcDropdown',
            data: this.isAloneApplyData,
            remote: true,
          },
          {
            key: 'date',
            label: '分成日期区间',
            comName: 'YkcDatePicker',
            change: this.handleChange,
          },
          {
            key: 'stationCode',
            label: '站点编码',
            comName: 'YkcInput',
            placeholder: '请输入站点编码',
          },
          {
            key: 'stationName',
            label: '电站名称',
            comName: 'YkcInput',
            placeholder: '请输入电站名称',
          },
          {
            key: 'stationOperatorId',
            label: '归属商家',
            placeholder: '请选择归属商家',
            comName: 'YkcDropdown',
            data: this.OperatorListData,
            remote: true,
          },
          {
            key: 'isDiscount',
            label: '优惠信息',
            placeholder: '请选择',
            comName: 'YkcDropdown',
            data: this.preferentialData,
            // change: this.discountChange,
            remote: false,
          },
        ];
      },
    },
    created() {
      const { stationId } = this.$route.query;
      if (stationId) {
        this.searchParam.stationId = stationId;
      }
      const curDate = new Date().getDate() - 1;
      const startDate = getLastTime('yyyy-MM-dd', curDate);
      const endDate = getLastTime('yyyy-MM-dd', 0);

      this.searchParam.date = [startDate, endDate];
      this.searchParam.startTime = `${startDate} 00:00:00`;
      this.searchParam.endTime = `${endDate} 23:59:59`;
      // this.searchParam.isDiscount = 0;
      this.requestOperatorList();
    },
    methods: {
      requestOperatorList() {
        directPage.userOperatorList({ keyword: '' }).then(res => {
          // eslint-disable-next-line no-unused-vars
          this.OperatorListData = (res || []).map(({ operatorId, name }) => ({
            name,
            id: String(operatorId),
          }));
        });
      },
      /**
       * 切换日月
       */
      handleChange(obj) {
        const { select, event } = obj;
        console.log('obj+++', obj);
        // 下拉选择操作默认日期
        if (event === 'select' && select === 'monthrange') {
          // 切换到月
          const monthStr = getLastYear(6, 1);
          const startDateStr = monthStr.startTime;
          const endDateStr = monthStr.endTime;

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.startTime = startDateStr;
          this.searchParam.endTime = endDateStr;
          // this.searchParam.billDateType = 2;
        } else if (event === 'select' && select === 'daterange') {
          // 切换到日
          const curDate = new Date().getDate() - 1;

          const startDateStr = getLastTime('yyyy-MM-dd', curDate);
          const endDateStr = getLastTime('yyyy-MM-dd', 0);

          this.searchParam.date = [startDateStr, endDateStr];
          this.searchParam.startTime = `${startDateStr} 00:00:00`;
          this.searchParam.endTime = `${endDateStr} 23:59:59`;
          // this.searchParam.billDateType = 1;
        }
      },

      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData+++', searchData);
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParam, searchData);
        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          // [this.searchParam.startTime, this.searchParam.endTime] = searchData.date;
          this.searchParam.startTime = `${searchData.date[0]} 00:00:00`;
          this.searchParam.endTime = `${searchData.date[1]} 23:59:59`;
        }
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParam, form);
        // 切换成日，默认状态
        const curDate = new Date().getDate() - 1;
        const startDate = getLastTime('yyyy-MM-dd', curDate);
        const endDate = getLastTime('yyyy-MM-dd', 0);

        this.searchParam.date = [startDate, endDate];
        this.searchParam.startTime = `${startDate} 00:00:00`;
        this.searchParam.endTime = `${endDate} 23:59:59`;
        this.searchParam.isDiscount = 1;
        // this.searchParam.billDateType = 1;
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
      },
    },
  };
</script>

<style scoped lang="scss">
  .card-info-area {
    box-sizing: border-box;
    .card-info {
      display: flex;
      box-sizing: border-box;
      flex-wrap: nowrap;
      flex-grow: 1;
      width: 892px;
      height: 125px;
      //line-height: 125px;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      align-content: center;
      align-items: center;
    }
    .common-icon {
      display: inline-flex;
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      margin-left: 30px;
      background: rgba(77, 140, 253, 0.1);
      border-radius: 14px;
      line-height: 70px;
      align-content: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      .big-icon {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .common-text {
      display: inline-flex;
      flex-direction: column;
      margin-left: 30px;
      .account-name {
        //width: 108px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 25px;
      }
      .purse-text {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
        //display: inline-flex;
        //align-items: center;
      }
      .purse-balance {
        //width: 261px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 40px;
        .purse-unit {
          width: 19px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }
    }
    .common-label-key {
      display: inline-block;
      width: 60px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 17px;
    }
    .common-label-val {
      display: inline-block;
      //width: 24px;
      //height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
      margin-left: 8px;
    }
    .white-stone {
      width: 16px;
      height: 125px;
    }
  }
</style>
