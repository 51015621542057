<!--
/**
  红包记录列表 
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="红包记录"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <!-- <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template> -->
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { code } from '@/utils';
  import { redPackRecordApi } from '@/service/apis';

  export default {
    name: 'CSideDriverAccountsList',

    data() {
      return {
        id: '',
        pageButtons: [],
        tableData: [],

        tableColumns: [
          { label: '桩编码', prop: 'pileCode', minWidth: '200px' },
          { label: '红包名称', prop: 'redPackName', minWidth: '200px' },
          { label: '建桩联系人', prop: 'pileName', minWidth: '200px' },
          { label: '建桩联系人手机号', prop: 'pilePhone', minWidth: '200px' },
          { label: '红包余额', prop: 'redPackBalance', minWidth: '100px' },
          { label: '电站名称', prop: 'stationName', minWidth: '300px' },
        ],

        tableOperateButtons: [
          {
            enabled: () => code('finance:account:refund:pass'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.$router.push({
                    path: '/financialManagement/redPack/useRecord',
                    query: {
                      pileId: row.pileId,
                    },
                  });
                }}>
                使用记录
              </ykc-button>
            ),
          },
          {
            enabled: () => code('finance:account:refund:refuse'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.$router.push({
                    path: '/financialManagement/redPack/grantRecord',
                    query: {
                      pileId: row.pileId,
                    },
                  });
                }}>
                发放记录
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          pileCode: '',
          pileName: '',
          pilePhone: '',
          redPackName: '',
          stationName: '',
        },
      };
    },
    created() {
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'pileCode',
            label: '桩编码',
            placeholder: '请输入桩编码',
          },
          {
            comName: 'YkcInput',
            key: 'pileName',
            label: '建桩联系人',
            placeholder: '请输入建桩联系人',
          },
          {
            comName: 'YkcInput',
            key: 'redPackName',
            label: '红包名称',
            placeholder: '请输入红包名称',
          },

          {
            comName: 'YkcInput',
            key: 'pilePhone',
            label: '建桩联系人手机号',
            placeholder: '请输入建桩联系人手机号',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
        ];
      },
    },
    methods: {
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },

      /** 根据条件查询数据 */
      searchTableList() {
        const params = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        redPackRecordApi.list(params).then(res => {
          console.log(res);
          this.tableData = res?.records || [];
          this.pageInfo.total = res?.total || 0;
        });
      },

      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
