<!--
/**
  红包记录列表 
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="红包发放记录"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { code } from '@/utils';
  import { redPackRecordApi } from '@/service/apis';

  export default {
    name: 'grantRecord',

    data() {
      return {
        pileId: '',

        tableData: [],

        tableColumns: [
          { label: '红包名称', prop: 'redPackName', minWidth: '200px' },
          { label: '发放日期', prop: 'createDate', minWidth: '200px' },
          { label: '发放金额（元）', prop: 'amount', minWidth: '200px' },
          { label: '发放模式', prop: 'mode', minWidth: '200px' },
        ],
        tableOperateButtons: [],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          date: [],
          startDate: '',
          endDate: '',
        },
      };
    },
    created() {
      this.pileId = Number(this.$route.query.pileId);
      this.searchTableList();
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },

      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'date',
            label: '发放日期区间',
            placeholder: '请选择发放日期区间',
          },
        ];
      },
    },
    methods: {
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },

      /** 根据条件查询数据 */
      searchTableList() {
        const params = {
          ...this.searchParams,
          pileId: this.pileId,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        redPackRecordApi.grantList(params).then(res => {
          console.log(res);
          this.tableData = res?.records || [];
          this.pageInfo.total = res?.total || 0;
        });
      },

      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        if (form.date.length > 0) {
          [this.searchParams.startDate, this.searchParams.endDate] = form.date;
        }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
